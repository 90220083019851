/* eslint-disable no-console */

import "./jquery.finger.js" //quiet


function isMobile(){ 
	console.log('isMobile');
	return (document.documentElement.clientWidth < 700)
}


jQuery(document).ready(function($) {
	
	// -------- Main Menu -------
	let top = $('#main-menu').outerHeight() + 77;
	$('#main-menu > li > .sub-menu').css({'top': top});
	$('#main-menu   li').removeClass('current-menu-item');
	$('#main-menu > li').first().addClass('current-menu-item');
	 
	
	$('#main-menu > li > a').on('click',
		function(e){
			if (isMobile()){
				e.preventDefault();
				$('#main-menu > li').removeClass('current-menu-item');
				$(this).parent().addClass('current-menu-item');
			}
		}
	)
	
	$('#main-menu > li > a').on('press',
		function(e){
			if (isMobile()){
				e.preventDefault();
				window.location.href = $(this).attr('href');
			}
		}
	)
	
	
	// --------- Header Image ---------
	
	var logoPosition = 999999;
	
	if ($('.js-logo').length){
		$('.parallax').on('scroll', function(){
			
			if ( $('.js-logo').offset().top < 3 ){
				logoPosition =  $('.parallax').scrollTop();			
				$('.js-logo').addClass('sticky');
			}
			
			if ( $('.parallax').scrollTop() < logoPosition ){
				logoPosition =  9999999;			
				$('.js-logo').removeClass('sticky');
	
			}
				
		});
	}
	
	// -------- Footer -------
	$('.footer-article').removeClass('open').addClass('closed');
	
	$('.footer-article').on('click',
		function(){
			if (isMobile()){
				$(this).toggleClass('open').toggleClass('closed');
			}
		}
	);
	
	
	
	// -------- Booking --------
	$('#js-booking-button').on('click',
		function(e){
			e.preventDefault();
			if (!$(this).prop('disabled')){
				$('.wc-booking-add-to-cart-popup-wrapper').addClass('show');
			}
		}
	);
	
	$('.checkbox-legal input').on('click',
		function(){
			if ( $(this).is(':checked') && isMobile() ){
				var offset = $('.checkbox-legal').offset();
				offset.left -= 20;
				offset.top += 11;
				$('html, body').animate({
				    scrollTop: offset.top,
				    scrollLeft: offset.left
				});
			}
		}
	);
	
	
	//-------- Messeges and Errors -------
	$('.woocommerce-message-close').on('click',
		function(e){
			e.preventDefault();
			$(this).closest('.woocommerce-message').hide();
		}
	);
	
	$(' .woocommerce-info-close').on('click',
		function(e){
			e.preventDefault();
			$(this).closest('.woocommerce-info').hide();
		}
	);
	
	$('.woocommerce-error-close').on('click',
		function(e){
			e.preventDefault();
			console.log('trst');
			$(this).closest('.woocommerce-error').addClass('.hideIt');
		}
	);
	
	$('.profilcard__wrapper img').on('click',function(){
		e.preventDefault();
		$(this).toggleClass('active');
	});
	
	$('.profilcard__wrapper img').on('mouseout',function(){
		e.preventDefault();
		$(this).removeClass('active');
	});
	
	//-------- Swiper -------
	
	var swiper;
	
	function checkSwiper(){
		if ($(window).width() < 699) {
		  swiper = new Swiper('.swiper-container', {
			  slidesPerView: 'auto',
			  spaceBetween: 22,
			  centeredSlides: true
			});
		}
		else {
			if (swiper !== undefined ){
				swiper.destroy(true, true);
			}
		}	
	}
	
	checkSwiper();
	
	$(window).resize(function() {
		checkSwiper();
	});
	
	//-------- Checkout -----------------
	
			
	$('.person-details-wrapper input, #persons_comments').change( function(){
		var numberpersons = parseInt( $('.person-details-wrapper').data('numberpersons') );
		var text = '';
		for (var i = 1; i <= numberpersons; i++){
		   text += 'Person ' + i+': ' + jQuery('input[name="persons_sex_'+i+'"]').val() + ' / '+ jQuery('input[name="persons_age_'+i+'"]').val() + '\n';
		   text += 'Level: ' + jQuery('input[name="persons_level_'+i+'"]').val() + ' -  ' + jQuery('input[name="persons_size_'+i+'"]').val() +  ' / ' + jQuery('input[name="persons_weight_'+i+'"]').val() + '\n\n';		
		}
		text += '\nAnmerkung:\n' + jQuery('#persons_comments').val();
		
		jQuery('#order_comments').val(text);
		
	});
	
	$('.js-age-details').hide();
	
	$('.js-person-age').change( function(){
		var isKid = false;
		$('.js-person-age').each(function() {
			if (parseInt($(this).val()) < 18){
				isKid = true;
			}
		});
		
		if (isKid){
			$('js-age-details').show();
		}else{
			$('js-age-details').hide();
		}
		
	});

	
	
});